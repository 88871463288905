import { Controller } from "@hotwired/stimulus"
import { useClickOutside } from "stimulus-use"

export default class extends Controller {
    static targets = ["frame"]
    static values = {
        url: String,
    }

    connect() {
        useClickOutside(this)
    }

    suggestIfNeeded(e) {
        if (!e.target.value) {
            this.closeSuggestions()
            return
        }
        const searchUrl = new URL(this.urlValue, location.origin)
        searchUrl.searchParams.append("query", e.target.value)
        searchUrl.searchParams.append("frameId", this.frameTarget.id)
        this.frameTarget.src = searchUrl
    }

    selectSuggestion(e) {
        this.dispatch("selected", {
            detail: {
                type: e.params.type,
                label: e.params.label,
                id: e.params.id,
            },
        })
    }

    closeSuggestions() {
        this.frameTarget.innerHTML = ""
    }

    clickOutside(e) {
        this.closeSuggestions()
    }
}
