import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["input", "hiddenInput"]
    fillSuggestion(e) {
        this.inputTarget.value = e.detail.label
        this.hiddenInputTarget.name = e.detail.type
        this.hiddenInputTarget.value = e.detail.id
        // changer la ligne ci-dessus en la ligne ci-dessous
        // lorsqu'on revient sur la SERP refonte (c.f. OfferController::displaySERP())
        //
        //this.hiddenInputTarget.value = e.detail.value
    }
}
