import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static values = {
        link: String,
    }

    navigateToOffer() {
        window.location.href = this.linkValue
    }
}
