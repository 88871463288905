import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["dialog", "form", "publicationDate"]
    static classes = ["modale"]

    toggleFacetsDialog() {
        document.body.classList.toggle("overflow-hidden")
        for (const classe of this.modaleClasses) {
            this.dialogTarget.classList.toggle(classe)
        }
    }
}
